import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from "./pages/Login";
import Dashboard from './pages/Dashboard';
import './App.css';
import { Provider } from "react-redux";
import store from "./store"
import PrivateRoute from './components/PrivateRoute';
import Unauthorized from "./pages/Unauthorized";
import UsageUpdate from "./pages/UsageUpdate";
import UpdateProfile from "./pages/UpdateProfile";
import EditUser from "./pages/EditUser";

function App() {
  return (
    <Provider store={store}>
      <Router>
          <Routes>
              <Route path="/" element={<Login/>} exact={true}/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/unauthorized" element={<Unauthorized/>}/>
              <Route path="/usageupdate" element={<UsageUpdate/>}/>
              <Route path="/updateprofile" element={<UpdateProfile/>}/>
              <Route path="/edituser" element={<EditUser/>}/>
          </Routes>
      </Router>
    </Provider>
  );
}


export default App;
