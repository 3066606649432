import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const AreaChart = ({ data }) => {
    // Prepare the data
    console.log("PRINT");
    console.log(data);
    const chartData = data.map(entry => ({
        x: entry.formattedDate.getTime(), // Use the pre-existing formattedDate
        y: entry.volume
    }));

    const options = {
        title: {
            text: 'Volume Over Time'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            }
        },
        yAxis: {
            title: {
                text: 'Volume'
            }
        },
        series: [{
            name: 'Volume',
            data: chartData,
            type: 'area', // Changed to area chart
            marker: {
                enabled: true
            },
            fillOpacity: 0.5 // Optional: Adjust the opacity of the fill
        }],
        tooltip: {
            xDateFormat: '%Y-%m-%d %H:%M:%S',
            pointFormat: 'Volume: <b>{point.y}</b>'
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AreaChart;
