import React from 'react';
import { 
  ModalContainer,
  Icon,
  CloseIcon,
  ModalWrapper,
  ModalMenu,
  ModalLink, 
  ModalLinkActive
} from './ModalElements';

import { navItems, navItemsAdmin } from '../../constants/constants';
import { useSelector } from 'react-redux';

const Modal = ({isOpen, toggle, pageOption, setPageOption}) => {

  const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);

  const handleModalLink = (id) => {
    setPageOption(id);
    toggle()
  }

  return (
    <ModalContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon/>
      </Icon>
      <ModalWrapper>
        <ModalMenu>
          {type === "usergamtooswater" && navItems.map((item, index) => {
            return(
              <div key={index}>
                {pageOption === item.id ? (
                  <ModalLinkActive onClick={() => handleModalLink(item.id)}>
                    {item.title}
                  </ModalLinkActive>
                ):(
                  <ModalLink onClick={() => handleModalLink(item.id)}>
                    {item.title}
                  </ModalLink>
                )}
              </div>
              
            )
          })}
          {type === "admingamtooswater" && navItemsAdmin.map((item, index) => {
            return(
              <div key={index}>
                {pageOption === item.id ? (
                  <ModalLinkActive onClick={() => handleModalLink(item.id)}>
                    {item.title}
                  </ModalLinkActive>
                ):(
                  <ModalLink onClick={() => handleModalLink(item.id)}>
                    {item.title}
                  </ModalLink>
                )}
              </div>
              
            )
          })}
        </ModalMenu>
      </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal
