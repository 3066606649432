import React from 'react';
import * as Icons from 'react-icons/fa';
import { DashSidebarContainer, Icon, IconToggel, SideItem, SideItemLogOut, SideItemText, SideLink, SideLinkActive, SideLinkLogOut, SideMenu} from './SidebarElements';
import { COLORS, navItems, navItemsAdmin } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { BiLogOut } from "react-icons/bi";
import { clearCredentials } from '../../slices/credentialsSlice';
import { useNavigate } from 'react-router';

const DashSidebar = ({sidebar, setSideBar, pageOption, setPageOption}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);
    const handelSidebar = () => {
        setSideBar(!sidebar);
    }

    const handleSidebarLink = (id) => {
        setPageOption(id);
    }

    const handleLogOut = () => {
        dispatch(clearCredentials());
        navigate('/');
    }

    return (
        <DashSidebarContainer sidebar={sidebar}>
            <SideMenu>
                {sidebar ? (
                    <IconToggel onClick={handelSidebar}>
                        <Icons.FaArrowLeft color={COLORS.FIRST}/>
                    </IconToggel>
                ): (
                    <IconToggel onClick={handelSidebar}>
                        <Icons.FaArrowRight color={COLORS.FIRST}/>
                    </IconToggel>
                )}

                {type === "usergamtooswater" && navItems.map(item => {
                    return(
                        <SideItem key={item.id}>
                            {sidebar ? (
                                <>
                                    {pageOption === item.id ? (
                                        <SideLinkActive onClick={() => handleSidebarLink(item.id)}>
                                            <Icon>
                                                {item.icon}
                                            </Icon>
                                            <SideItemText>
                                                {item.title}
                                            </SideItemText>
                                        </SideLinkActive>
                                    ):(
                                        <SideLink onClick={() => handleSidebarLink(item.id)}>
                                            <Icon>
                                                {item.icon}
                                            </Icon>
                                            <SideItemText>
                                                {item.title}
                                            </SideItemText>
                                        </SideLink>
                                    )}
                                </>
                            ):(
                                <>
                                {pageOption === item.id ? (
                                    <SideLinkActive onClick={() => handleSidebarLink(item.id)}>
                                        <Icon >
                                            {item.icon}
                                        </Icon>
                                    </SideLinkActive>
                                ):(
                                    <SideLink onClick={() => handleSidebarLink(item.id)}>
                                        <Icon >
                                            {item.icon}
                                        </Icon>
                                    </SideLink>
                                )}
                                </>
                            )}
                            
                        </SideItem>
                    )
                })}
                {type === "admingamtooswater" && navItemsAdmin.map(item => {
                    return(
                        <SideItem key={item.id}>
                            {sidebar ? (
                                <>
                                    {pageOption === item.id ? (
                                        <SideLinkActive onClick={() => handleSidebarLink(item.id)}>
                                            <Icon>
                                                {item.icon}
                                            </Icon>
                                            <SideItemText>
                                                {item.title}
                                            </SideItemText>
                                        </SideLinkActive>
                                    ):(
                                        <SideLink onClick={() => handleSidebarLink(item.id)}>
                                            <Icon>
                                                {item.icon}
                                            </Icon>
                                            <SideItemText>
                                                {item.title}
                                            </SideItemText>
                                        </SideLink>
                                    )}
                                </>
                            ):(
                                <>
                                {pageOption === item.id ? (
                                    <SideLinkActive onClick={() => handleSidebarLink(item.id)}>
                                        <Icon >
                                            {item.icon}
                                        </Icon>
                                    </SideLinkActive>
                                ):(
                                    <SideLink onClick={() => handleSidebarLink(item.id)}>
                                        <Icon >
                                            {item.icon}
                                        </Icon>
                                    </SideLink>
                                )}
                                </>
                            )}
                            
                        </SideItem>
                    )
                })}

                {sidebar ? (
                    <SideItemLogOut>
                        <SideLinkLogOut onClick={() => handleLogOut()}>
                            <Icon>
                                <BiLogOut />
                            </Icon>
                            <SideItemText>
                                Log Out
                            </SideItemText>
                        </SideLinkLogOut>
                    </SideItemLogOut>
                ):(
                    <SideItemLogOut>
                        <SideLinkLogOut onClick={() => handleLogOut()}>
                            <Icon>
                                <BiLogOut />
                            </Icon>
                        </SideLinkLogOut>
                    </SideItemLogOut>
                )}
            </SideMenu>
        </DashSidebarContainer>
    );
}

export default DashSidebar;