import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const SettingsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.BLACK};
    background-color: ${COLORS.GRAYLIGHT};
    padding: 30px;
    width: 100%;
    max-width: 500px;
    font-weight: bold;

    &:hover {
        background-color: ${COLORS.GRAY2};
        cursor: pointer;
    }
    
`