import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const HomeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const HomeSubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 50%;
`

export const Title = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 50px;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.BLACK};
    background-color: ${COLORS.WHITE};
    padding: 30px;
    width: 100%;
    max-width: 500px;
    font-weight: bold;
    font-size: 24px;

    &:hover {
        background-color: ${COLORS.FIRST};
        color: ${COLORS.LIGHTWHITE};
        cursor: pointer;
    }
`

export const ButtonText = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
`