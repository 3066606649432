import React, { useState } from 'react';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { AmountContainer, Button, ButtonContainer, CounterContainer } from './CounterElements';

const Counter = ({counterAmount, setCounterAmount, counterMin=0, counterMax=10000}) => {

    const handleIncrement = () => {
        if (counterAmount < counterMax) {
            setCounterAmount(counterAmount + 1);
        }
        
    }

    const handleDecrement = () => {
        if (counterAmount > counterMin) {
            setCounterAmount(counterAmount - 1);
        }
    }

    return (
        <CounterContainer>
            <ButtonContainer>
                <Button onClick={handleDecrement}>
                    <FaMinus />
                </Button>
            </ButtonContainer>
            <AmountContainer>
                {counterAmount}
            </AmountContainer>
            <ButtonContainer>
                <Button onClick={handleIncrement}>
                    <FaPlus />
                </Button>
            </ButtonContainer>
            
        </CounterContainer>
    )
};

export default Counter;