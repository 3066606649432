import React, { useEffect, useState } from 'react';
import { Button, ButtonContainer, Error, Input, InputContainer, Space, Title} from './EditUserElements';
import { CONNECTIONS, groupButtonsEditUser } from '../../constants/constants';
import GroupButtons from '../../components/GroupButtons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OrbitProgress } from 'react-loading-indicators';
import { useSelector } from 'react-redux';
import { EditUserContainer, EditUserSubContainer } from './EditUserElements';
import Navbar from '../../components/NavBar';
import { useLocation } from 'react-router';

const EditUser = () => {

    const location = useLocation();
    const user = location.state?.user;

    const {id, token} = useSelector((state) => state.credentials);
    const [editUserOption, setEditUserOption] = useState(0);
    const [name, setName] = useState(user.name);
    const [surname, setSurname] = useState(user.surname);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const checkPasswordStrength = (password) => {
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        const numberRegex = /[0-9]/;
        
        const hasUppercase = uppercaseRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);
        const hasNumber = numberRegex.test(password);
        const isLengthValid = password.length >= 8;

        if (hasUppercase && hasSpecialChar && hasNumber && isLengthValid) {
            return true;
        } else {
            return false
        }
    };

    const showSuccessToast = () => {
        toast.success("Add User was successful!", {
            position: "top-right",
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError("");

        var check = true;
        if (password !== repassword) {
            check = false
            setError("Passwords does not match")
        }
        if (!checkPasswordStrength(password)) {
            check = false;
            setError("Weak Password! Needs: Uppercase, Special Character, Length of 8 and numerical values!")
        }
        if ( password === "" || repassword === "") {
            check = false;
            setError("Fill in all infromation");
        }

        if (check) {
            try {

                var url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/update/password/admin`;
    
                var requestBody = {
                    CredentialId: id,
                    UserId: user.id,
                    NewPassword: password,
                    NewPasswordConfirmation: repassword,
                };
    
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                });
    
                if (!response.ok) {
                    if (response.status === "400") {
                        setError("Fill in all infromation")
                    }
                    if (response.status === "409") {
                        setError("User allready exists")
                    }
                    if (response.status === "500") {
                        setError("Server Error")
                    }
                } else {
                    showSuccessToast()
                }
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        setLoading(false);
    }

    const handleUpdateUserInfo = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError("");

        var check = true;
        if (!validateEmail(email)) {
            check = false;
            setError("Invalid Email");
        }
        if (!validatePhone(phone)) {
            check = false;
            setError("Invalid Phone Number")
        }
        if (email === "" || surname === "" || name === "" || phone === "") {
            check = false;
            setError("Fill in all infromation");
        }

        if (check) {
            try {

                var url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/update/userinfo/admin`;
    
                var requestBody = {
                    CredentialId: id,
                    UserId: user.id,
                    Email: email,
                    Name: name,
                    Surname: surname,
                    Phone: phone,
                };
    
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                });
    
                if (!response.ok) {
                    if (response.status === "400") {
                        setError("Fill in all infromation")
                    }
                    if (response.status === "409") {
                        setError("User allready exists")
                    }
                    if (response.status === "500") {
                        setError("Server Error")
                    }
                } else {
                    showSuccessToast()
                }
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        setLoading(false);
    }


    return (
        <EditUserContainer>
            <Navbar option={0}/>
            <Space></Space>
            <GroupButtons selectedOption={editUserOption} setSelectedOption={setEditUserOption} list={groupButtonsEditUser}/>
            {editUserOption === 0 && (
                <EditUserSubContainer>
                    <Title>Update User Info</Title>
                    <InputContainer>
                        <Input
                            id="nameInput"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Name'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="surnameInput"
                            type="text"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            placeholder='Surname'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="emailInput"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="phoneInput"
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder='Phone'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    {error != "" && error != null && (
                        <Error>
                            {error}
                        </Error>
                    )}
                    <ToastContainer />
                    {loading ? (
                        <EditUserSubContainer>
                            <OrbitProgress color="#000" size="small" text="" textColor="" />
                        </EditUserSubContainer>
                    ):(
                        <ButtonContainer>
                            <Button onClick={handleUpdateUserInfo}>
                                Update User Info
                            </Button>
                        </ButtonContainer>
                    )}
                    
                </EditUserSubContainer>
            )}
            {editUserOption === 1 && (
                <EditUserSubContainer>
                    <Title>Update Password</Title>
                    <InputContainer>
                        <Input
                            id="passwordInput"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Password'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="repasswordInput"
                            type="password"
                            value={repassword}
                            onChange={(e) => setRePassword(e.target.value)}
                            placeholder='Confirm Password'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    {error != "" && error != null && (
                        <Error>
                            {error}
                        </Error>
                    )}
                    <ToastContainer />
                    {loading ? (
                        <EditUserSubContainer>
                            <OrbitProgress color="#000" size="small" text="" textColor="" />
                        </EditUserSubContainer>
                    ):(
                        <ButtonContainer>
                            <Button onClick={handleUpdatePassword}>
                                Update Password
                            </Button>
                        </ButtonContainer>
                    )}
                </EditUserSubContainer>
            )}
        </EditUserContainer>
    )
};

export default EditUser;