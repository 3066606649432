import React from 'react';
import { Link } from 'react-router-dom';
import { GiDesertSkull } from "react-icons/gi";

const UnauthorizedPage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Access Denied</h1>
      <p>You do not have the necessary permissions to view this page.</p>
      <p>
        <GiDesertSkull size={500} color="#FF0000" />
      </p>

      <Link to="/">Go Back to Home</Link>
    </div>
  );
};

export default UnauthorizedPage;
