import React, { useEffect, useState } from 'react';
import { Button, ButtonContainer, ButtonContainerDeleteUser, ButtonContainerEditUser, ButtonDeleteUser, ButtonEditUser, Error, Input, InputContainer, Label, SearchContainer, SubTitle, Title, UserInfoContainer, UserInfoTextContainer, UsersContainer, UsersSubContainer } from './UsersElements';
import { CONNECTIONS, groupButtonsUsers } from '../../constants/constants';
import GroupButtons from '../GroupButtons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OrbitProgress } from 'react-loading-indicators';
import { useSelector } from 'react-redux';
import { MdEdit } from "react-icons/md";
import { useNavigate } from 'react-router';
import { MdDelete } from "react-icons/md";

const Users = () => {

    const navigate = useNavigate();
    const {id, token} = useSelector((state) => state.credentials);

    const [usersOption, setUsersOption] = useState(0);
    const [search, setSearch] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        if (search === "") {
            setFilteredUsers(allUsers);
        } else {
            const filtered = allUsers.filter(user => {
                const fullName = `${user.name} ${user.surname}`;
                return fullName.toLowerCase().includes(search.toLowerCase());
            });
            setFilteredUsers(filtered);
        }
    }, [search, allUsers])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const checkPasswordStrength = (password) => {
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        const numberRegex = /[0-9]/;
        
        const hasUppercase = uppercaseRegex.test(password);
        const hasSpecialChar = specialCharRegex.test(password);
        const hasNumber = numberRegex.test(password);
        const isLengthValid = password.length >= 8;

        if (hasUppercase && hasSpecialChar && hasNumber && isLengthValid) {
            return true;
        } else {
            return false
        }
    };

    const showSuccessToast = () => {
        toast.success("Add User was successful!", {
            position: "top-right",
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleAddUser = async (e) => {

        e.preventDefault();

        setLoading(true);
        setError("");

        var check = true;
        if (!validateEmail(email)) {
            check = false;
            setError("Invalid Email");
        }
        if (!validatePhone(phone)) {
            check = false;
            setError("Invalid Phone Number")
        }
        if (password !== repassword) {
            check = false
            setError("Passwords does not match")
        }
        if (!checkPasswordStrength(password)) {
            check = false;
            setError("Weak Password! Needs: Uppercase, Special Character, Length of 8 and numerical values!")
        }
        if (email === "" || surname === "" || name === "" || phone === "" || password === "" || repassword === "") {
            check = false;
            setError("Fill in all infromation");
        }

        if (check) {
            try {

                var url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/register`;
    
                var requestBody = {
                    CredentialId: id,
                    Email: email,
                    Password: password,
                    Name: name,
                    Surname: surname,
                    Phone: phone,
                };
    
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                });
    
                if (!response.ok) {
                    if (response.status === "400") {
                        setError("Fill in all infromation")
                    }
                    if (response.status === "409") {
                        setError("User allready exists")
                    }
                    if (response.status === "500") {
                        setError("Server Error")
                    }
                } else {
                    showSuccessToast();
                    getAllUsers();
                }
    
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
        setLoading(false);
    }

    const getAllUsers = async () => {
        try {
            var url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/allinfo/${id}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                if (response.status === "400") {
                    setError("Fill in all infromation")
                }
                if (response.status === "409") {
                    setError("User allready exists")
                }
                if (response.status === "500") {
                    setError("Server Error")
                }
            } else {
                const data = await response.json(); 
                console.log(data)
                setAllUsers(data)
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const handleEditUser = (user) => {
        navigate("/edituser", { state: { user } });
    }

    const handleDeleteUser = async (user) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete user ${user.name} ${user.surname}?`);
        if (!confirmDelete) {
            return; // Exit if user cancels the operation
        }
    
        setLoading(true);
        try {
            const url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/deleteUserAdmin`;
    
            const requestBody = {
                CredentialId: id,
                UserId: user.id,
            };
    
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });
    
            if (!response.ok) {
                if (response.status === 400) {
                    setError("Fill in all information");
                } else if (response.status === 409) {
                    setError("User already exists");
                } else if (response.status === 500) {
                    setError("Server Error");
                }
            } else {
                showSuccessToast();
                getAllUsers();
                showSuccessToast();
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <UsersContainer>
            <GroupButtons selectedOption={usersOption} setSelectedOption={setUsersOption} list={groupButtonsUsers}/>
            {usersOption === 0 && (
                <UsersSubContainer>
                    <h1>All Users</h1>
                    <SearchContainer>
                        <InputContainer>
                            <Input
                                id="nameInput"
                                type="text"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder='Search User'
                                autoComplete="off"
                            /> 
                        </InputContainer>
                    </SearchContainer>
                    {filteredUsers && filteredUsers.map((user, index) => (
                        <UserInfoContainer key={index}>
                            <UserInfoTextContainer>
                                <Label>{user.name} {user.surname}</Label>
                                <Label>{user.email}</Label>
                                <Label>{user.phone}</Label>
                            </UserInfoTextContainer>
                            
                            <ButtonContainerEditUser>
                                <ButtonEditUser onClick={() => handleEditUser(user)}>
                                    <MdEdit />
                                </ButtonEditUser>
                            </ButtonContainerEditUser>
                            <ButtonContainerDeleteUser>
                                <ButtonDeleteUser onClick={() => handleDeleteUser(user)}>
                                    <MdDelete />
                                </ButtonDeleteUser>
                            </ButtonContainerDeleteUser>
                        </UserInfoContainer>
                    ))}
                </UsersSubContainer>
            )}
            {usersOption === 1 && (
                <UsersSubContainer>
                    <Title>Add User</Title>
                    <InputContainer>
                        <Input
                            id="nameInput"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Name'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="surnameInput"
                            type="text"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            placeholder='Surname'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="emailInput"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="phoneInput"
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder='Phone'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="passwordInput"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Password'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    <InputContainer>
                        <Input
                            id="repasswordInput"
                            type="password"
                            value={repassword}
                            onChange={(e) => setRePassword(e.target.value)}
                            placeholder='Confirm Password'
                            autoComplete="off"
                        /> 
                    </InputContainer>
                    {error != "" && error != null && (
                        <Error>
                            {error}
                        </Error>
                    )}
                    <ToastContainer />
                    {loading ? (
                        <UsersSubContainer>
                            <OrbitProgress color="#000" size="small" text="" textColor="" />
                        </UsersSubContainer>
                    ):(
                        <ButtonContainer>
                            <Button onClick={handleAddUser}>
                                Add User
                            </Button>
                        </ButtonContainer>
                    )}
                    
                </UsersSubContainer>
            )}
        </UsersContainer>
    )
};

export default Users;