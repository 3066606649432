import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const CounterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    padding: 10px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    }
    
`

export const AmountContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
`