import React, { useState } from 'react';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { Button, ButtonContainer, SettingsContainer } from './SettingsElements';
import { useNavigate } from 'react-router';

const Settings = () => {
    const navigate = useNavigate();

    const handleUpdateProfile = () => {
        navigate("/updateprofile")
    }

    return (
        <SettingsContainer>
            <h1>Settings</h1>
            <ButtonContainer>
                <Button onClick={handleUpdateProfile}>
                    Update Password
                </Button>
            </ButtonContainer>
        </SettingsContainer>
    )
};

export default Settings;