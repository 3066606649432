import React from 'react';
import { Button, ButtonContainer, ButtonText, Content, HomeContainer, HomeSubContainer, Title } from './HomeElements';
import { useSelector } from 'react-redux';
import { IoIosStats } from "react-icons/io";
import { FaUsers } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import * as Icons from "react-icons/fa";

const Home = ({setPageOption}) => {
    const {id, token, email, name, surname, phone, type} = useSelector((state) => state.credentials);

    const handleOnClick = (option) => {
        setPageOption(option)
    }

    return (
        <HomeContainer>
            <Title>Welcome to Gamtoos Water Portal {name} {surname}</Title>
            {type && type === "usergamtooswater" && (
                <Content>
                    <HomeSubContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(2)}>
                                <Icons.FaHandHoldingWater />
                                <ButtonText>
                                    Usage
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(3)}>
                                <IoMdSettings />
                                <ButtonText>
                                    Settings
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                    </HomeSubContainer>
                </Content>
            )}
            {type && type === "admingamtooswater" && (
                <Content>
                    <HomeSubContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(2)}>
                                <Icons.FaHandHoldingWater />
                                <ButtonText>
                                    All Usage
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(3)}>
                                <IoIosStats />
                                <ButtonText>
                                    Stats
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                    </HomeSubContainer>
                    <HomeSubContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(4)}>
                                <FaUsers />
                                <ButtonText>
                                    Users
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                        <ButtonContainer>
                            <Button onClick={() => handleOnClick(5)}>
                                <IoMdSettings />
                                <ButtonText>
                                    Settings
                                </ButtonText>
                            </Button>
                        </ButtonContainer>
                    </HomeSubContainer>
                </Content>
            )}
            
        </HomeContainer>
    )
};

export default Home;