import styled from 'styled-components';
import { COLORS } from "../../constants/constants";
import {Link as LinkS} from 'react-scroll';

export const DashSidebarContainer = styled.aside.withConfig({
    shouldForwardProp: (prop) => prop !== 'sidebar'
  })`
    width: ${({ sidebar }) => (sidebar ? '200px' : '60px')};
    background: ${COLORS.GRAYLIGHT};
    position: fixed;
    transition: 0.3s all ease-in-out;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 20px;
    overflow: hidden;
    height: 85%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 0px;
    }
`

export const SideMenu = styled.ul`
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.2s all ease-out;
    margin-top:30px;
    flex-grow: 1;
`

export const SideItem = styled.li`
    list-style: none;
    width: 100%;
    height: 3rem;
`

export const SideLink = styled.div`
    color: ${COLORS.FIRST};
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
        color: ${COLORS.BLACK};
        transition: 0.2s ease-in-out;
    }
`

export const SideItemLogOut = styled.li`
    list-style: none;
    width: 100%;
    height: 3rem;
    margin-top: auto;
    margin-bottom: 10px;
`

export const SideLinkLogOut = styled.div`
    color: ${COLORS.FIRST};
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
        color: ${COLORS.DANGER};
        transition: 0.2s ease-in-out;
        background-color: ${COLORS.DANGER_BACKGROUND};
        border-radius: 20px;
    }
`

export const SideLinkActive = styled.div`
    color: ${COLORS.WHITE};
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    background-color: ${COLORS.FIRST};
    border-radius:20px;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
        color: ${COLORS.WHITE};
        transition: 0.2s ease-in-out;
    }
`

export const IconToggel = styled.div`
    list-style: none;
    width: 100%;
    margin-left: 40px;

    &:hover {
        color: ${COLORS.BLACK};
        transition: 0.2s ease-in-out;
    }
`

export const Icon = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left: 15px;
`

export const SideItemText = styled.div`
    margin-left: 10px
`