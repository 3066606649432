import styled from 'styled-components';
import {IoIosArrowRoundBack} from 'react-icons/io';
import {Link as LinkS} from 'react-scroll';

export const ModalContainer = styled.aside.withConfig({
    shouldForwardProp: (prop) => prop !== 'isOpen'
  })`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
  `;


export const CloseIcon = styled(IoIosArrowRoundBack)`
    color: #000;

    &:hover {
        color: #063970;
        transition: 0.2s ease-in-out;
    }
`


export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 3rem;
    cursor: pointer;
    outline: none;
`

export const ModalWrapper = styled.div`
    color: #fff;
`

export const ModalMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px){
        grid-template-rows: reapeat(6, 60px);
    }
`

export const ModalLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #000;
    cursor: pointer;

    &:hover {
        color: #063970;
        transition: 0.2s ease-in-out;
    }
`

export const ModalLinkActive = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        color: #063970;
        transition: 0.2s ease-in-out;
    }
`
