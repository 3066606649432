import React, { useEffect, useState } from 'react';
import { BarGraphContainer, FilterContainer, FiltersContainer, FilterTitel, GraphTitle, GraphTitleContainer, QuickStatsContainer, QuickStatsSpan, Space, StatsContainer, TableContainer, TableSubTitle, TableTitle } from './StatsElements';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CONNECTIONS, customDropdownStyles, timePeriodOptions, usageSectionOptionsFilter } from '../../constants/constants';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import AreaChart from '../AreaChart';
import BarChart from '../BarChart';

const Stats = () => {
    const { id, token } = useSelector((state) => state.credentials);

    const [allUsage, setAllUsage] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({ value: '2', label: 'Last Week' });
    const [selectedLine, setSelectedLine] = useState({ value: 'all', label: 'All' });
    const [totalVolume, setTotalVolume] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [xAxisMin, setXAxisMin] = useState();
    const [xAxisMax, setXAxisMax] = useState();
    const [totalVolumesUsers, setTotalVolumesUsers] = useState();
    const [totalVolumesUsersTableData, setTotalVolumesUsersTableData] = useState();

    const getHour = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return timeString;
    };

    const getHourValue = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', hour12: false });
        return parseInt(timeString);
    };

    const getYear = (datetime) => {
        const date = new Date(datetime);
        return date.getUTCFullYear();
    };

    const getMonth = (datetime) => {
        const date = new Date(datetime);
        return (date.getUTCMonth() + 1).toString().padStart(2, '0');
    };

    const getDate = (datetime) => {
        const date = new Date(datetime);
        return date.getUTCDate().toString().padStart(2, '0');
    };

    const isInNextWeek = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        
        // Set time to 0 for both dates for an accurate day comparison
        now.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
    
        // Calculate the difference in milliseconds
        const diffTime = date - now;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the date is within the next 7 days
        return diffDays >= 0 && diffDays < 7;
    };

    const isInLastDay = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const diffTime = now - date;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the difference is less than or equal to 1 day
        return diffDays <= 1 && diffDays >= 0;
    };

    const isInToday = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
    
        // Start of today (midnight)
        const startOfToday = new Date(today);
        startOfToday.setHours(0, 0, 0, 0);
    
        // End of today (11:59:59.999 PM)
        const endOfToday = new Date(today);
        endOfToday.setHours(23, 59, 59, 999);
    
        // Check if the date falls within today's range
        return date >= startOfToday && date <= endOfToday;
    };
    
    const isInLastWeek = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Calculate the difference in milliseconds
        const diffTime = now - date;
    
        // Convert milliseconds to days
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
    
        // Check if the difference is less than or equal to 7 days
        return diffDays <= 7 && diffDays >= 0;
    };
    
    const isInLastMonth = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 1 month
        const lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
    
        // Check if the date is after the calculated last month
        return date >= lastMonth && date <= now;
    };
    
    const isInLastSixMonths = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 6 months
        const lastSixMonths = new Date();
        lastSixMonths.setMonth(lastSixMonths.getMonth() - 6);
    
        // Check if the date is after the calculated last six months
        return date >= lastSixMonths  && date <= now;
    };
    
    const isInLastYear = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
    
        // Get the current date minus 1 year
        const lastYear = new Date();
        lastYear.setFullYear(lastYear.getFullYear() - 1);
    
        // Check if the date is after the calculated last year
        return date >= lastYear  && date <= now;
    };
    
    // Generate the 24-hour time range for the x-axis (00:00 to 23:00)
    const generate24HourRange = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hourString = i.toString().padStart(2, '0') + ":00";
            hours.push(hourString);
        }
        return hours;
    };

    const mergeUsageWithFullDay = (usageData) => {
        const fullDay = generate24HourRange();
        const usageMap = usageData.reduce((acc, item) => {
            acc[item.hour] = item;
            return acc;
        }, {});

        return fullDay.map((hour) => {
            return usageMap[hour] ? usageMap[hour] : { hour, volume: 0 };
        });
    };

    const getNextDay = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        return date.toISOString();
    };

    const formattedDate = (year, month, date, hour) => {
        const hour_int = parseInt(hour, 10); // Ensure hour is an integer
        const date_int = parseInt(date, 10); // Ensure date is an integer
        const month_int = parseInt(month, 10) - 1; // Adjust month to be zero-based and ensure it's an integer
        const year_int = parseInt(year, 10); // Ensure year is an integer

        // Construct the Date object
        const formattedDate = new Date(year_int, month_int, date_int, hour_int);
        return formattedDate
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options); // Use 'en-GB' for the desired format
    }

    const formatToISO = (year, month, date, hour) => {
        const hourInt = parseInt(hour, 10); // Ensure hour is an integer
        const dateInt = parseInt(date, 10); // Ensure date is an integer
        const monthInt = parseInt(month, 10) - 1; // Adjust month to zero-based
        const yearInt = parseInt(year, 10); // Ensure year is an integer
    
        // Create the Date object
        const dateObject = new Date(yearInt, monthInt, dateInt, hourInt);
    
        // Return ISO string format
        return dateObject.toISOString();
    };

    useEffect(() => {
        getAllUsage();
    }, [selectedTimePeriod, selectedLine]);

    const getAllUsage = async () => {
        setTableData([])
        try {
            const url = `https://${CONNECTIONS.SERVICE_URL_UA}/api/admin/all/${id}`;
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                if (response.status === 409) setError("User already exists");
                if (response.status === 500) setError("Server Error");
                return;
            }
    
            const data = await response.json();
            let extractedData = [];
            const totalVolumes = new Map();
    
            data.forEach((item) => {
                let check = false;
                // Check selectedLine and selectedTimePeriod filters
                const datePart_CHECK = item.date.split("T")[0];
                const timePart_CHECK = item.startTime.split("T")[1];
                const combinedDateTimeString_CHECK = `${datePart_CHECK}T${timePart_CHECK}`;
                const startDateTime_CHECK = new Date(combinedDateTimeString_CHECK);

                if (
                    (selectedLine.value === "all" || item.section === selectedLine.value) &&
                    checkTimePeriod(selectedTimePeriod.value, combinedDateTimeString_CHECK)
                ) {
                    check = true;
                }
    
                if (check) {
                    
                    // Calculate volume per hour
                    if (item.type === "pivot") {
                        if (item.size === 2) {
                            item.volume = item.amount * 30
                        } else if (item.size === 3) {
                            item.volume = item.amount * 50
                        } else if (item.size === 4) {
                            item.volume = item.amount * 80
                        } else if (item.size === 5) {
                            item.volume = item.amount * 115
                        } else if (item.size === 6) {
                            item.volume = item.amount * 165
                        } else if (item.size === 7) {
                            item.volume = item.amount * 200
                        } else {
                            item.volume = 0;
                        }
                    }

                    //Increment the volume for the specific user
                    const userIdByUser = item.credentialId;
                    const volumeByUser = item.volume;

                    const datePart = item.date.split("T")[0];
                    const timePart = item.startTime.split("T")[1];
                    const combinedDateTimeString = `${datePart}T${timePart}`;
                    const startDateTime = new Date(combinedDateTimeString);
                    const nextDateTime = new Date(item.date);
                    nextDateTime.setDate(nextDateTime.getDate() + 1);
    
                    if (parseInt(getHourValue(item.endTime)) < parseInt(getHourValue(item.startTime))) {
                        
                        const hourDifference = (Math.abs(24 - parseInt(getHourValue(item.startTime))) + parseInt(getHourValue(item.endTime)));
                        let hourlyVolume = 0;
                        if (item.type === "pivot") {
                            hourlyVolume = item.volume;
                        } else {
                            hourlyVolume = item.volume / (hourDifference);
                        }
                        
                        for (let i = 0; i <= hourDifference; i++) {
                            const currentHour = new Date(startDateTime);
                            currentHour.setUTCHours(currentHour.getUTCHours() + i);
                            if (i === 0) {
                                if (getHourValue(currentHour) < getHourValue(item.startTime)) {
                                    extractedData.push({
                                        hour: getHourValue(currentHour),
                                        date: getDate(nextDateTime),
                                        month: getMonth(nextDateTime),
                                        year: getYear(nextDateTime),
                                        formattedDate: formattedDate(getYear(nextDateTime),getMonth(nextDateTime),getDate(nextDateTime),getHourValue(currentHour)),
                                        volume: 0,
                                    });
                                } else {
                                    extractedData.push({
                                        hour: getHourValue(currentHour),
                                        date: getDate(currentHour),
                                        month: getMonth(currentHour),
                                        year: getYear(currentHour),
                                        formattedDate: formattedDate(getYear(currentHour),getMonth(currentHour),getDate(currentHour),getHourValue(currentHour)),
                                        formattedDateISO: formatToISO(getYear(currentHour),getMonth(currentHour),getDate(currentHour),getHourValue(currentHour)),
                                        formattedDateISO: formatToISO(getYear(currentHour),getMonth(currentHour),getDate(currentHour),getHourValue(currentHour)),
                                        volume: 0,
                                    });
                                }
                            } else {
                                if (getHourValue(currentHour) < getHourValue(item.startTime)) {
                                    extractedData.push({
                                        hour: getHourValue(currentHour),
                                        date: getDate(nextDateTime),
                                        month: getMonth(nextDateTime),
                                        year: getYear(nextDateTime),
                                        formattedDate: formattedDate(getYear(nextDateTime),getMonth(nextDateTime),getDate(nextDateTime),getHourValue(currentHour)),
                                        formattedDateISO: formatToISO(getYear(nextDateTime),getMonth(nextDateTime),getDate(nextDateTime),getHourValue(currentHour)),
                                        volume: hourlyVolume,
                                    });

                                    if (totalVolumes.has(userIdByUser)) {
                                        totalVolumes.set(userIdByUser, totalVolumes.get(userIdByUser) + hourlyVolume);
                                    } else {
                                        totalVolumes.set(userIdByUser, hourlyVolume);
                                    }
                                } else {
                                    extractedData.push({
                                        hour: getHourValue(currentHour),
                                        date: getDate(currentHour),
                                        month: getMonth(currentHour),
                                        year: getYear(currentHour),
                                        formattedDate: formattedDate(getYear(currentHour),getMonth(currentHour),getDate(currentHour),getHourValue(currentHour)),
                                        formattedDateISO: formatToISO(getYear(currentHour),getMonth(currentHour),getDate(currentHour),getHourValue(currentHour)),
                                        volume: hourlyVolume,
                                    });
                                    if (totalVolumes.has(userIdByUser)) {
                                        totalVolumes.set(userIdByUser, totalVolumes.get(userIdByUser) + hourlyVolume);
                                    } else {
                                        totalVolumes.set(userIdByUser, hourlyVolume);
                                    }
                                }
                            }
                        }
                    } else {

                        const hourDifference = Math.abs(parseInt(getHourValue(item.endTime)) - parseInt(getHourValue(item.startTime))); // Convert ms to hours
                        let hourlyVolume = 0;
                        if (item.type === "pivot") {
                            hourlyVolume = item.volume;
                        } else {
                            hourlyVolume = item.volume / (hourDifference);
                        }
                        // Loop through each hour and spread the volume
                        for (let i = 0; i <= hourDifference; i++) {
                            const currentHour = new Date(startDateTime);
                            
                            currentHour.setUTCHours(currentHour.getUTCHours() + i);
                            
                            if (i === 0) {
                                extractedData.push({
                                    hour: parseInt(getHourValue(currentHour)),
                                    date: getDate(startDateTime),
                                    month: getMonth(startDateTime),
                                    year: getYear(startDateTime),
                                    formattedDate: formattedDate(getYear(startDateTime),getMonth(startDateTime),getDate(startDateTime),getHourValue(currentHour)),
                                    formattedDateISO: formatToISO(getYear(startDateTime),getMonth(startDateTime),getDate(startDateTime),getHourValue(currentHour)),
                                    volume: 0,
                                });
                            } else {
                                extractedData.push({
                                    hour: parseInt(getHourValue(currentHour)),
                                    date: getDate(startDateTime),
                                    month: getMonth(startDateTime),
                                    year: getYear(startDateTime),
                                    formattedDate: formattedDate(getYear(startDateTime),getMonth(startDateTime),getDate(startDateTime),getHourValue(currentHour)),
                                    formattedDateISO: formatToISO(getYear(startDateTime),getMonth(startDateTime),getDate(startDateTime),getHourValue(currentHour)),
                                    volume: hourlyVolume,
                                });
                                if (totalVolumes.has(userIdByUser)) {
                                    totalVolumes.set(userIdByUser, totalVolumes.get(userIdByUser) + hourlyVolume);
                                } else {
                                    totalVolumes.set(userIdByUser, hourlyVolume);
                                }
                            }
                        }
                    }
                }
            });

            setTotalVolumesUsers(totalVolumes);

            setAllUsage(extractedData)

            let totalVolumeCount = 0;

            extractedData.forEach(item => {
                const itemVolume = item.volume;
                totalVolumeCount += itemVolume;
            });

            setTotalVolume(totalVolumeCount);

            const volumeByDay = extractedData.reduce((acc, curr) => {
                const date = new Date(curr.formattedDateISO);
                date.setHours(date.getHours() + 2);
                const dateKeyFormat = date.toISOString().split("T")[0];
                if (!acc[dateKeyFormat]) {
                    acc[dateKeyFormat] = { date: dateKeyFormat, totalVolume: 0 };
                }
                acc[dateKeyFormat].totalVolume += curr.volume;
                return acc;
            }, {});

            const volumeArray = Object.values(volumeByDay);

            setTableData(volumeArray)

            //create an array that corrosponds to the time period
            if (selectedTimePeriod.value === "0") {
                const today = new Date();
                const oneWeekFuture = new Date();
                oneWeekFuture.setDate(today.getDate() + 7);

                const todayISO = today.toISOString();
                const oneWeekFutureISO = oneWeekFuture.toISOString();

                setXAxisMin(todayISO);
                setXAxisMax(oneWeekFutureISO);
            } else if (selectedTimePeriod.value === "1") {
                const today = new Date();

                // Start of today (midnight)
                const startOfToday = new Date(today);
                startOfToday.setHours(0, 0, 0, 0);

                // End of today (11:59:59.999 PM)
                const endOfToday = new Date(today);
                endOfToday.setHours(23, 59, 59, 999);

                const startOfTodayISO = startOfToday.toISOString();
                const endOfTodayISO = endOfToday.toISOString();

                setXAxisMin(startOfTodayISO);
                setXAxisMax(endOfTodayISO);
            } else if (selectedTimePeriod.value === "2") {
                const today = new Date();
                const oneWeekBack = new Date();
                oneWeekBack.setDate(today.getDate() - 7);
                const todayISO = today.toISOString();
                const oneWeekBackISO = oneWeekBack.toISOString();

                setXAxisMin(oneWeekBackISO)
                setXAxisMax(todayISO)
            } else if (selectedTimePeriod.value === "3") {
                const today = new Date();
                const oneMonthBack = new Date();
                oneMonthBack.setMonth(today.getMonth() - 1);

                const todayISO = today.toISOString();
                const oneMonthBackISO = oneMonthBack.toISOString();

                setXAxisMin(oneMonthBackISO);
                setXAxisMax(todayISO);
            } else if (selectedTimePeriod.value === "4") {
                const today = new Date();
                const sixMonthsBack = new Date();
                sixMonthsBack.setMonth(today.getMonth() - 6);
                
                const todayISO = today.toISOString();
                const sixMonthsBackISO = sixMonthsBack.toISOString();
                
                setXAxisMin(sixMonthsBackISO);
                setXAxisMax(todayISO);
            } else if (selectedTimePeriod.value === "5") {
                const today = new Date();
                const oneYearBack = new Date();
                oneYearBack.setFullYear(today.getFullYear() - 1);

                const todayISO = today.toISOString();
                const oneYearBackISO = oneYearBack.toISOString();

                setXAxisMin(oneYearBackISO);
                setXAxisMax(todayISO);
            } else if (selectedTimePeriod.value === "6") {
                if (extractedData.length > 0) {
                    let oldestDate = new Date(extractedData[0].formattedDate);
                    let newestDate = new Date(extractedData[0].formattedDate);
                
                    extractedData.forEach(item => {
                        const currentDate = new Date(item.formattedDate);
                        if (currentDate < oldestDate) {
                            oldestDate = currentDate;
                        }
                        if (currentDate > newestDate) {
                            newestDate = currentDate;
                        }
                    });
                    oldestDate.setDate(oldestDate.getDate() - 2);
                    newestDate.setDate(newestDate.getDate() + 2);
                
                    const oldestDateISO = oldestDate.toISOString();
                    const newestDateISO = newestDate.toISOString();
                
                    setXAxisMin(oldestDateISO);
                    setXAxisMax(newestDateISO);
                }
            }
    
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (totalVolumesUsers) {
                const keys = Array.from(totalVolumesUsers.keys());
                if (keys.length !== 0) {
                    try {
                        const url = `https://${CONNECTIONS.SERVICE_URL_CA}/api/getUsersInfo`;
    
                        const response = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            },
                            body: JSON.stringify(keys),
                        });
    
                        if (!response.ok) {
                            if (response.status === 409) setError("User already exists");
                            if (response.status === 500) setError("Server Error");
                            return;
                        }
    
                        const data = await response.json();
    
                        // Construct table data
                        const volumeUsersTableData = data.map((item) => {
                            return {
                                username: `${item.name} ${item.surname}`,
                                volume: totalVolumesUsers.get(item.credentialId),
                            };
                        });
    
                        setTotalVolumesUsersTableData(volumeUsersTableData);
                    } catch (err) {
                        setError(err.message);
                    } finally {
                        setLoading(false);
                    }
                }
            }
        };
    
        fetchUserInfo(); // Call the async function
    }, [totalVolumesUsers]);
    
    
    
    // Helper function to check the selected time period
    const checkTimePeriod = (periodValue, date) => {
        if (periodValue === "0" && isInNextWeek(date)) return true;
        if (periodValue === "1" && isInToday(date)) return true;
        if (periodValue === "2" && isInLastWeek(date)) return true;
        if (periodValue === "3" && isInLastMonth(date)) return true;
        if (periodValue === "4" && isInLastSixMonths(date)) return true;
        if (periodValue === "5" && isInLastYear(date)) return true;
        if (periodValue === "6") return true;
        return false;
    };
    return (
        <StatsContainer>
            <h1>Stats</h1>
            <FiltersContainer>
                <FilterContainer>
                    <FilterTitel>Time Period</FilterTitel>
                    <Select 
                        value={selectedTimePeriod}
                        onChange={(value) => setSelectedTimePeriod(value)} 
                        options={timePeriodOptions} 
                        styles={customDropdownStyles} 
                    />
                </FilterContainer>
                <Space />
                <FilterContainer>
                    <FilterTitel>Line</FilterTitel>
                    <Select 
                        value={selectedLine}
                        onChange={(value) => setSelectedLine(value)} 
                        options={usageSectionOptionsFilter} 
                        styles={customDropdownStyles} 
                    />
                </FilterContainer>
            </FiltersContainer>
            <QuickStatsContainer>
                <QuickStatsSpan>
                    Total Volume: {totalVolume.toFixed(2)} m³
                </QuickStatsSpan>
            </QuickStatsContainer>
            <BarGraphContainer>
                <BarChart data={allUsage} heigth={"500px"} xAxisStart={xAxisMin} xAxisEnd={xAxisMax}/>
            </BarGraphContainer>
            
            <TableTitle>
                Volume by Date
            </TableTitle>
            {tableData && tableData.length > 0 && (
                <TableContainer>
                    <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '10px', overflow: 'hidden', border: '1px solid transparent' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{formatDate(entry.date)}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.totalVolume.toFixed(2)} m³</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </TableContainer>
                
            )}

            <TableTitle>
                Volume by User
            </TableTitle>
            <TableSubTitle>
                (This is over the whole time period selected)
            </TableSubTitle>
            {totalVolumesUsersTableData && totalVolumesUsersTableData.length > 0 && (
                <TableContainer>
                    <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '10px', overflow: 'hidden', border: '1px solid transparent' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>UserName</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Total Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            {totalVolumesUsersTableData.map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.username}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{entry.volume.toFixed(2)} m³</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </TableContainer>
                
            )}
            
            
        </StatsContainer>
    );
};

export default Stats;
