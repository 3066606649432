import styled from 'styled-components';
import {IoIosArrowRoundBack} from 'react-icons/io';
import { COLORS } from "../../constants/constants";

export const LoginContainer = styled.div`
    padding: 10px;
`
export const BackIcon = styled(IoIosArrowRoundBack)`
    color: ${COLORS.BLACK};
    font-size: 50px;

    &:hover {
        color: ${COLORS.FIRST};
        transition: 0.2s ease-in-out;
    }
`

export const IconContainer = styled.div`

`

export const FormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 300px);
`

export const FormWrapper = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
`;

export const FormTitle = styled.h2`
    margin-bottom: 20px;
    color: ${COLORS.FIRST};
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const FormLabel = styled.label`
    margin-bottom: 8px;
    font-size: 14px;
    color: ${COLORS.SEVENTH};
    text-align: left;
`;

export const FormInput = styled.input`
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid ${COLORS.WHITE};
    font-size: 16px;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const FormButton = styled.button`
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`;
