import React, { useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { AllUsageContainer, AllUsageItemContainer, AllUsageItemsContainer, AllUsageItemSpan, AllUsageItemSubContainer } from './AllUsageElements';
import { useSelector } from 'react-redux';
import { CONNECTIONS } from '../../constants/constants';

const AllUsage = () => {

    const {id, token} = useSelector((state) => state.credentials);

    const [allUsage, setAllUsage] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllUsage();
    }, []);

    const getAllUsage = async () => {
        try {

            var url = `https://${CONNECTIONS.SERVICE_URL_UA}/api/admin/all/${id}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                if (response.status === "409") {
                    setError("User allready exists")
                }
                if (response.status === "500") {
                    setError("Server Error")
                }
            } else {
                const data = await response.json(); 
                setAllUsage(data)
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    const getHour = (datetime) => {
        const date = new Date(datetime);
        date.setHours(date.getUTCHours() + 2);
        const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return timeString;
    };

    return (
        <AllUsageContainer>
            <h1>All Usage</h1>
            {allUsage && allUsage.map((item, index) => (
                <AllUsageItemsContainer key={index}>
                    {item.type === "dam" && (
                        <AllUsageItemContainer>
                            <AllUsageItemSubContainer>
                                <AllUsageItemSpan>
                                    Type: {item.type}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Line: {item.section}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Amount: {item.amount}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Volume: {item.volume} m³
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Start Time: {getHour(item.startTime)}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    End Time: {getHour(item.endTime)}
                                </AllUsageItemSpan>
                            </AllUsageItemSubContainer>
                        </AllUsageItemContainer>
                    )}
                    {item.type === "pivot" && (
                        <AllUsageItemContainer>
                            <AllUsageItemSubContainer>
                                <AllUsageItemSpan>
                                    Type: {item.type}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Line: {item.section}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Amount: {item.amount}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Towers: {item.size}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    Start Time: {getHour(item.startTime)}
                                </AllUsageItemSpan>
                                <AllUsageItemSpan>
                                    End Time: {getHour(item.endTime)}
                                </AllUsageItemSpan>
                            </AllUsageItemSubContainer>
                        </AllUsageItemContainer>
                    )} 
                </AllUsageItemsContainer>
            ))}
        </AllUsageContainer>
    )
};

export default AllUsage;