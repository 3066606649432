import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  token: null,
  email: null,
  name: null,
  surname: null,
  phone: null,
  type: null,
};

const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    setCredentials(state, action) {
      const { token, email, id, name, surname, phone, type } = action.payload;
      state.id = id;
      state.token = token;
      state.email = email;
      state.name = name;
      state.surname = surname;
      state.phone = phone;
      state.type = type;

      
    },
    clearCredentials(state) {
      state.token = null;
      state.email = null;
      state.id = null;
      state.name = null;
      state.surname = null;
      state.phone = null;
      state.type = null;
    },
  },
});

export const { setCredentials, clearCredentials } = credentialsSlice.actions;

export default credentialsSlice.reducer;
