import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonLeft, ButtonLeftActive, ButtonMiddle, ButtonRight, GroupButtonsContainer, ButtonRightActive, ButtonMiddleActive } from './GroupButtonsElements';

const GroupButtons = ({selectedOption, setSelectedOption, list}) => {

    const handleSelectButton = (option) => {
        setSelectedOption(option);
    }

    return (
        <GroupButtonsContainer>
            {list.map((item, index) => (
                <div key={index}>
                    {index === 0 && (
                        <>
                        {selectedOption === item.id ? (
                            <ButtonLeftActive onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonLeftActive>
                        ):(
                            <ButtonLeft onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonLeft>
                        )}
                        </>
                    )}
                    {index !== 0 && index !== (list.length - 1) && (
                        <>
                        {selectedOption === item.id ? (
                            <ButtonMiddleActive onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonMiddleActive>
                        ):(
                            <ButtonMiddle onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonMiddle>
                        )}
                        </>
                    )}
                    {index === (list.length - 1) && (
                        <>
                        {selectedOption === item.id ? (
                            <ButtonRightActive onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonRightActive>
                        ):(
                            <ButtonRight onClick={() => handleSelectButton(item.id)}>
                                {item.icon}
                                {item.title}
                            </ButtonRight>
                        )}
                        </>
                    )}
                </div>
                
            ))}
            
        </GroupButtonsContainer>
    )
};

export default GroupButtons;