import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const StatsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    padding: 10px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    } 
`

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 30px;
`

export const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`

export const FilterTitel = styled.div`
    font-weight: bold;
`

export const Space = styled.div`
    width: 10px;
`

export const QuickStatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 90%;
    margin-bottom: 20px;
`

export const QuickStatsSpan = styled.span`
    font-size: 18px;
    font-weight: bold;
`

export const GraphTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`
export const GraphTitle = styled.span`
    font-size: 20px;
    font-weight: bold;
`

export const BarGraphContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
`;

export const TableTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.5rem;
`;

export const TableSubTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1rem;
`;