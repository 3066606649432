import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const UsageUpdateContainer = styled.div`

`
export const UsageUpdateSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const UsageUpdateSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.WHITE};
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding : 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const UsageUpdateTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
`

export const DropdownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ScheduledTimeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LabelUsage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
`

export const UpdateButton = styled.div`
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 10px;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`

export const VolumeInput = styled.input`
    width: 200px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1.2rem;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const Metric = styled.span`
  font-size: 1.5rem;
  padding-left: 5px;
  color: #333;
`;

export const Error = styled.span`
  font-size: 1.2rem;
  padding-left: 5px;
  color: ${COLORS.DANGER};
  border-radius: 10px;
  background-color: ${COLORS.DANGER_BACKGROUND};
  padding: 10px;
`;

export const UsageUpdateItemSpan = styled.span`
`
