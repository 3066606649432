import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const UsageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
`
export const UsageSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const UsageSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.WHITE};
    width: 500px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding : 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const UsageTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
`

export const AddButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

export const AddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`;

export const DropdownContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ScheduledTimeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LabelUsage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
`

export const SubmitButton = styled.div`
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 10px;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`

export const VolumeInput = styled.input`
    width: 200px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1.2rem;

    &:focus {
        border-color: ${COLORS.FIRST};
        outline: none;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const Metric = styled.span`
  font-size: 1.5rem;
  padding-left: 5px;
  color: #333;
`;

export const Error = styled.span`
  font-size: 1.2rem;
  padding-left: 5px;
  color: ${COLORS.DANGER};
  border-radius: 10px;
  background-color: ${COLORS.DANGER_BACKGROUND};
  padding: 10px;
`;

export const Success = styled.span`
  font-size: 1.2rem;
  padding-left: 5px;
  color: ${COLORS.SUCCESS};
  border-radius: 10px;
  background-color: ${COLORS.SUCCESS_BACKGROUND};
  padding: 10px;
`;

export const UsageItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
`

export const UsageItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    background-color: ${COLORS.WHITE};

`

export const UsageItemSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const UsageItemSpan = styled.span`
`

export const EditButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

export const EditButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${COLORS.SECOND};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 65px;
    height: 45px;
    font-weight: bold;

    &:hover {
        background-color: ${COLORS.FIRST};
    }
`;

export const DeleteButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: 10px;
`

export const DeleteButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: ${COLORS.DANGER};
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 45px;
    height: 45px;

    &:hover {
        background-color: ${COLORS.DANGER_BACKGROUND};
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`