import React, { useState } from 'react';
import Navbar from '../../components/NavBar';
import {
    Input,
    InputContainer,
    UpdateProfileContainer,
    Title,
    UpdateButton,
    SubContainer,
    Error,
} from './UpdateProfileElements';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CONNECTIONS } from '../../constants/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OrbitProgress } from 'react-loading-indicators';

const UpdateProfile = () => {
    const {id, token} = useSelector((state) => state.credentials);

    const [currentpassword, setCurrentPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showSuccessToast = () => {
        toast.success("Password Updated!", {
            position: "top-right",
            autoClose: 3000, // Auto close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleUpdatePassword = async () => {
        if (newpassword === "" || repassword === ""  || currentpassword === "") {
            setError("Fill in all information.")
        } else if (newpassword !== repassword) {
            setError("Passwords does not match.")
        } else {
            try {
                var url = `http://${CONNECTIONS.SERVICE_URL_CA}/api/update/password`;

                var requestBody = {
                    CredentialId: id,
                    CurrentPassword: currentpassword,
                    NewPassword: newpassword,
                    NewPasswordConfirmation: repassword,
                }

                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                });


                if (!response.ok) {
                    if (response.status === "409") {
                        setError("User allready exists")
                    }
                    if (response.status === "500") {
                        setError("Server Error")
                    }
                } else {
                    setError("")
                    showSuccessToast()
                    navigate("/dashboard")
                }

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <Navbar option={0}/> 
            <UpdateProfileContainer>
                <Title>
                    Update Password
                </Title>
                <InputContainer>
                    <Input
                        id="passwordACurrentInput"
                        type="password"
                        value={currentpassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder='Current Password'
                        autoComplete="current-password"
                    /> 
                </InputContainer>
                <InputContainer>
                    <Input
                        id="passwordNewInput"
                        type="password"
                        value={newpassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder='New Password'
                        autoComplete="new-password"
                    /> 
                </InputContainer>
                <InputContainer>
                    <Input
                        id="repasswordInput"
                        type="password"
                        value={repassword}
                        onChange={(e) => setRePassword(e.target.value)}
                        placeholder='Confirm New Password'
                        autoComplete="new-password"
                    /> 
                </InputContainer>
                {error != "" && error != null && (
                    <Error>
                        {error}
                    </Error>
                )}
                <ToastContainer />
                {loading ? (
                    <SubContainer>
                        <OrbitProgress color="#000" size="small" text="" textColor="" />
                    </SubContainer>
                ):(
                <UpdateButton onClick={handleUpdatePassword}>
                    Update Password
                </UpdateButton>
                )}
            </UpdateProfileContainer>
        </div>
    )
};

export default UpdateProfile;
