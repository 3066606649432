import styled from 'styled-components';
import { COLORS } from "../../constants/constants";

export const AllUsageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: ${COLORS.LIGHTWHITE};
    background-color: ${COLORS.FIRST};
    padding: 10px;

    &:hover {
        background-color: ${COLORS.SECOND};
        cursor: pointer;
    }
    
`

export const AllUsageItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
`

export const AllUsageItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    background-color: ${COLORS.WHITE};

`

export const AllUsageItemSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const AllUsageItemSpan = styled.span`
`