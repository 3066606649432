import React from 'react';
import { animateScroll } from 'react-scroll';
import { 
    Nav,
    NavBarContainer,
    NavLogo,
    Img,
    MobileIcon,
    NavUserName,
    Icon,
    NavRightContainer
} from './NavBarElements';
import {FaBars} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaUser } from "react-icons/fa";

const Navbar = ({toggle, option}) => {
    const toggleHome = () => {
        animateScroll.scrollToTop();
    }

    const {name, surname} = useSelector((state) => state.credentials);

    if (option === 0) {
        return (
            <Nav>
                <NavBarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <Img src={require('../../images/logo_full.png')}/>
                    </NavLogo>
                </NavBarContainer>
            </Nav>
        )
    } else if (option === 1) {
        return (
            <Nav>
                <NavBarContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <Img src={require('../../images/logo_full.png')}/>
                    </NavLogo>

                    <NavRightContainer>
                        <NavUserName>
                            <Icon>
                                <FaUser />
                            </Icon>
                            {name} {surname}
                        </NavUserName>
                        <MobileIcon onClick={toggle}>
                            <FaBars/>
                        </MobileIcon>
                    </NavRightContainer>
                </NavBarContainer>
            </Nav>
        )
    }

    
}

export default Navbar
