import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { COLORS } from '../../constants/constants';

const BarChart = ({ data, heigth, width, xAxisStart, xAxisEnd }) => {
    // Create a map to accumulate volumes based on the formatted date
    const volumeMap = data.reduce((acc, entry) => {
        const timeStamp = entry.formattedDate.getTime();
        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
        const newTimeStamp = timeStamp + twoHoursInMilliseconds;
        if (!acc[newTimeStamp]) {
            acc[newTimeStamp] = 0; // Initialize if the timestamp doesn't exist
        }
        acc[newTimeStamp] += entry.volume; // Accumulate volume
        return acc;
    }, {});

    // Transform the map into an array of chart data
    const chartData = Object.entries(volumeMap).map(([timeStamp, volume]) => ({
        x: Number(timeStamp), // Convert the timestamp back to a number
        y: volume // Use the accumulated volume
    }));

    const options = {
        chart: {
            type: 'column',
            backgroundColor: COLORS.LIGHTWHITE,
            plotBorderWidth: 1,
            plotBorderColor: '#ccc',
        },
        title: {
            text: 'Volume Over Time'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            },
            tickInterval: 24 * 3600 * 1000, // Set interval to daily
            min: xAxisStart ? new Date(xAxisStart).getTime() : undefined,
            max: xAxisEnd ? new Date(xAxisEnd).getTime() : undefined,
        },
        yAxis: {
            title: {
                text: 'Volume'
            },
            gridLineWidth: 0 // Removes grid lines for a cleaner look
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                groupPadding: 0,
                borderWidth: 0,
                color: COLORS.THIRD,
                borderRadius: 3
            }
        },
        series: [{
            name: 'Volume',
            data: chartData
        }],
        tooltip: {
            xDateFormat: '%Y-%m-%d %H:%M:%S',
            pointFormat: 'Volume: <b>{point.y}</b>'
        }
    };

    return <HighchartsReact containerProps={{ style: { height: heigth, width: "100%" } }} highcharts={Highcharts} options={options} />;
};


export default BarChart;
